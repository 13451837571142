module.exports = {
  GOOGLE_KEY:
    "731946990731-8hs0shq0ism8httfr0ojnmkrfosgu397.apps.googleusercontent.com",
  API_BASE: "https://attendance-api.greatmanagerinstitute.com",
  BASE_URL: "https://attendance-api.greatmanagerinstitute.com",
  // API_BASE: "http://localhost:2500",
  // BASE_URL: "http://localhost:2500",
  MOMENT_UI_DATE_FORMAT: "DD MMM YY",
  API_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  API_DATE_FORMAT: "YYYY-MM-DD",
  DATE_START_FORMAT: "YYYY-MM-DD 00:00:00",
  DATE_END_FORMAT: "YYYY-MM-DD 23:59:59",
  JWT_SECRET: "usSKNcCHzVg0NyY8iWO9",
  MOMENT_FULL_MONTH: "MMMM",
  MOMENT_HOLIDAY_FORMAT: "ddd MMM D",
  EARNED_LEAVE: 5,
  OPTIONAL_HOLIDAY: 7,
  WORK_WITHOUT_PAY: 6,
  MICROSOFT_CLIENT_ID: "329c16ab-ba51-40a0-be7b-54d675ef988b",
  DEFAULT_PROFILE_PIC:
    "https://lh5.googleusercontent.com/-_8lLpray-RU/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rcVlIiKZpiJTPo5WgvSNj0JdmvaEw/s96-c/photo.jpg",
};
