import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import { verifyToken } from "../helpers/signJwtToken";


export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      let token = localStorage.getItem("token");

      if (token) {
        try {
          const verifiedToken = await verifyToken(token);
          let isLoggedIn = verifiedToken?.payload?.sub?.email ? true : false;
          setAuthenticated(isLoggedIn);
        } catch (error) {
          // Handle verification error, e.g., token is invalid
          setAuthenticated(false);
        }
      } else {
        // No token, user is not authenticated
        setAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []); // Run the effect only once on component mount

  if (authenticated === null) {
    // Waiting for authentication check, you can render a loading indicator here
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     const checkAuthentication = async () => {
//       let token = localStorage.getItem("token");
//       const verifiedToken = await verifyToken(token);
//       const status =
//         verifiedToken?.payload?.sub?.email !== "" &&
//         verifiedToken?.payload?.sub?.email !== undefined
//           ? true
//           : false;

//       setIsLoggedIn(status);
//     };

//     checkAuthentication();
//   }, []);

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         console.log(isLoggedIn, "PROPS");
//         if (!isLoggedIn) {
//           return (
//             <Redirect
//               to={{ pathname: "/login", state: { from: props.location } }}
//             />
//           );
//         }

//         return <Component {...props} />;
//       }}
//     />
//   );
// };
