import React from "react";
import { NavLink } from "react-router-dom";
import "../header/Header.css";
import $ from "jquery";
import { verifyToken } from "../../helpers/signJwtToken";
import "../../assets/css/linearicons.css";

const GMI_NAVIGATORLINKS = [
  {
    name: "E-Wallet-Reimbursement",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/Reimbursement/formperma/-v4oB1wOCmsJwifNFMufIIxHZnc5Uopw8EmggBQlRd0",
  },
  {
    name: "Laptop Details",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/LaptopDetails/formperma/rJe3GBjB-R1tSXI0Wbj_CEDni73DlfG0qUyJoU0oIOo",
  },
  {
    name: "Recognition/Thank You",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/RewardsandRecognition/formperma/9B1eoCcze_rdmsyKs4ziYa88Qfd2mkp1VqDCNDcQiLo",
  },
  {
    name: "Request to Admin",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/RequesttoAdminOperations/formperma/pRP7pTQQmWQ7njKsmsavYKn8KL1a3PSmUieO_AWahcQ",
  },
  {
    name: "Employee Database Update",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/EMPLOYEEDATABASE1/formperma/nxYIRVGG3KGdsbyw_mNmCuuR9XG9XPOH4zYAQTzS8qA",
  },
  {
    name: "Weekly Plan-Friendly Reminders",
    link: "https://docs.google.com/spreadsheets/d/1bTS9VqBIL7YXpZG7LmL6qcCH2QZdewLq2QloOIb6k40/edit?usp=sharing",
  },
  {
    name: "Park Your Quarterly KRA",
    link: "https://drive.google.com/drive/folders/1-9YFNMY9U6EEVzI9luIdxSHpSBzzPmYY?usp=sharing",
  },
  {
    name: "Invoice Request For Hi-Touch New Contracts",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/InvoiceRequestForHiTouchClient/formperma/gX0Xc2sUQ_HrKDR7wbBQKyXl5JAt3gbfPz-K9sn5Lro",
  },
  {
    name: "Invoice Request For GPMS Clients",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/InvoiceRequestForGPMSClients/formperma/advVW0XtqNDzalrfRE4U1ctdDLK6ekYf9bJuPvHFBJY",
  },
  {
    name: "Invoice Request For B2B Hi-Tech Clients",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/InvoiceRequestForB2BHiTechNewContracts/formperma/HWC8rKfEf-HgNshCoG64OJKS8Evt_4uNYaipROoaTX4",
  },
  {
    name: "Hi-Touch Second or Third Invoice",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/HiTouchFirstorSecondInvoice/formperma/s46BARGaGHqAih9NQwCFOUJYIgGp3BcpNLFGvzlA634",
  },
  {
    name: "Invoice Request For Masterclass",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/InvoiceRequestForMasterclass/formperma/C4S-G8tNxfz5P95JdJI2R1vCipgQjJHKPV1p3YViwjg",
  },
  {
    name: "Investment Declaration FY 2022-23",
    link: "https://forms.zohopublic.com/greatmanagerresearchconsulta/form/InvestmentDeclarationFY20212022/formperma/YO_dYFFQwB6LyKRACZvdCSnVnRN1Fw4WOI0wjNnZcnw",
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: null,
    };
    this.logoutClick = this.logoutClick.bind(this);
  }

  async getUserDetails() {
    let userDetails = localStorage.getItem("token");
    if (userDetails) {
      const verifiedToken = await verifyToken(userDetails);
      return verifiedToken?.payload?.sub;
    }
    return null;
  }

  logoutClick() {
    localStorage.clear();
    window.location.reload();
  }

  async componentDidMount() {
    const userDetails =  await this.getUserDetails();
    this.setState({
      userDetails
    })
    if ($("#nav-menu-container").length) {
      var $mobile_nav = $("#nav-menu-container").clone().prop({
        id: "mobile-nav",
      });
      $mobile_nav.find("> ul").attr({
        class: "",
        id: "",
      });
      $("body").append($mobile_nav);
      $("body").prepend(
        '<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>'
      );
      $("body").append('<div id="mobile-body-overly"></div>');
      $("#mobile-nav")
        .find(".menu-has-children")
        .prepend('<i class="lnr lnr-chevron-down"></i>');

      $(document).on("click", ".menu-has-children i", function (e) {
        $(this).next().toggleClass("menu-item-active");
        $(this).nextAll("ul").eq(0).slideToggle();
        $(this).toggleClass("lnr-chevron-up lnr-chevron-down");
      });

      $(document).on("click", "#mobile-nav-toggle", function (e) {
        $("body").toggleClass("mobile-nav-active");
        $("#mobile-nav-toggle i").toggleClass("lnr-cross lnr-menu");
        $("#mobile-body-overly").toggle();
      });

      $(document).on("click", function (e) {
        var container = $("#mobile-nav, #mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($("body").hasClass("mobile-nav-active")) {
            $("body").removeClass("mobile-nav-active");
            $("#mobile-nav-toggle i").toggleClass("lnr-cross lnr-menu");
            $("#mobile-body-overly").fadeOut();
          }
        }
      });
    } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
      $("#mobile-nav, #mobile-nav-toggle").hide();
    }
  }

  render() {
    return (
      <header id="header">
        <div className="container main-menu">
          <div className="row align-items-center d-flex">
            <div id="logo">
              <a href="index.html">
                <img src="/images/gmi_logo.png" alt="" title="" />
              </a>
            </div>
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <li>
                  <NavLink to="/dashboard" activeClassName="menu-active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/leave" activeClassName="menu-active">
                    Leave
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/holiday-list" activeClassName="menu-active">
                    Holiday List
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/task-list" activeClassName="menu-active">
                    Today's Task
                  </NavLink>
                </li>
                <li className="menu-has-children">
                  <NavLink to="/requests" activeClassName="menu-active">
                    GMI Navigator
                  </NavLink>
                  <ul>
                    {GMI_NAVIGATORLINKS.map((value, idx) => {
                      return (
                        <li key={idx}>
                          <a target="_blank" href={value.link}>
                            {value.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                {typeof this.state.userDetails?.userType !== "undefined" &&
                this.state.userDetails?.userType == 2 ? (
                  <li className="menu-has-children">
                    <NavLink to="/requests" activeClassName="menu-active">
                      Admin Options
                    </NavLink>
                    <ul>
                      <li>
                        <NavLink to="/requests" activeClassName="menu-active">
                          Requests
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/report" activeClassName="menu-active">
                          Reports
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/users" activeClassName="menu-active">
                          Users
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </nav>
          </div>
          <div className="row align-items-right hidden-xs hidden-sm">
            <div className="shortProfileInfo">
              <div className="childDiv">
                <div className="profileDropdown">
                  <div className="dropdown">
                    <button className="dropbtn">
                      {typeof this.state.userDetails?.name !== "undefined" &&
                      this.state.userDetails?.name != null &&
                      this.state.userDetails?.name !== ""
                        ? this.state.userDetails?.name
                        : "User"}
                      <i className="fa fa-angle-down"></i>
                    </button>
                    <div className="dropdown-content">
                      <a onClick={this.logoutClick}>Logout</a>
                    </div>
                  </div>
                </div>
                <div className="profileImg">
                  {typeof this.state.userDetails?.profilePic !== "undefined" &&
                  this.state.userDetails?.profilePic != null &&
                  this.state.userDetails?.profilePic !== "" ? (
                    <img src={this.state.userDetails?.profilePic} width="24px" />
                  ) : (
                    <img src="/images/user-test.jpg" width="24px" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
