import { SignJWT, importJWK, jwtVerify } from "jose";
const config = require("../config/config");

const jwtSecretJWK = {
  kty: "oct",
  k: config.JWT_SECRET,
  alg: "HS256",
};
const secretKey = await importJWK(jwtSecretJWK, { alg: "HS256" });

export const signToken = async (userPayload) => {
  const jwtToken = await new SignJWT({ sub: userPayload })
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime("30d")
    .sign(secretKey);

  return jwtToken;
};

export const verifyToken = (jwtToken) => {
  try {
    const verifiedToken = jwtVerify(jwtToken, secretKey);
    return verifiedToken;
  } catch (error) {
    console.error("Token verification failed:", error);
    throw new Error("Token verification failed");
  }
};
