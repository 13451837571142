import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { verifyToken } from "../helpers/signJwtToken";

export const PrivateRouteAdmin = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      let token = localStorage.getItem("token");
      if (token) {
        try {
          const verifiedToken = await verifyToken(token);
          let isLoggedIn = verifiedToken?.payload?.sub?.email ? true : false;
          let isAdmin =
            verifiedToken?.payload?.sub?.userType !== "undefined" &&
            verifiedToken?.payload?.sub?.userType === 2
              ? true
              : false;
          setIsAdmin(isAdmin);
          setAuthenticated(isLoggedIn);
        } catch (error) {
          // Handle verification error, e.g., token is invalid
          setAuthenticated(false);
        }
      } else {
        // No token, user is not authenticated
        setAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []); // Run the effect only once on component mount

  if (authenticated === null) {
    // Waiting for authentication check, you can render a loading indicator here
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if (!isAdmin) {
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};
