import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import config from "../../config/config";
import queryString from "query-string";
import "../userList/userList.css";

const AddUser = ({ show, handleClose, user, refreshUserList }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [paidLeaves, setPaidLeaves] = useState("");
  const [earnedLeaves, setEarnedLeaves] = useState("");

  useEffect(() => {
    if (user) {
      setName(user.name || "");
      setEmail(user.email || "");
      setPaidLeaves(user.plBalance || "");
      setEarnedLeaves(user.chBalance || "");
    }
  }, [user]);

  const updateUserDetails = () => {
    const data = {
      name,
      email,
      plBalance: paidLeaves,
      chBalance: earnedLeaves,
      userId: user ? user.id : "",
    };

    const api =
      user && user.id ? "/user/updateUserDetails" : "/user/insertUserDetails";

    if (name && email && paidLeaves && earnedLeaves) {
      Axios.post(config.API_BASE + api, data)
        .then((response) => {
          if (response.data.status) {
            refreshUserList();
            handleClose();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="addUsersMain">
      <Modal.Header closeButton>
        <Modal.Title>{user ? "Edit User" : "Create New User"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Paid Leaves</Form.Label>
            <Form.Control
              type="number"
              value={paidLeaves}
              onChange={(e) => setPaidLeaves(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Earned Leaves</Form.Label>
            <Form.Control
              type="number"
              value={earnedLeaves}
              onChange={(e) => setEarnedLeaves(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={updateUserDetails}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [ifActive, setIfActive] = useState(1);

  useEffect(() => {
    getUserList();
  }, [ifActive]);

  const getUserList = () => {
    Axios.get(
      config.API_BASE +
        "/user/getUserList?" +
        queryString.stringify({
          ifActive,
        })
    )
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.data || []);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleModal = (user = null) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleToggle = () => {
    setIfActive(ifActive === 1 ? 0 : 1);
  };

  const prepareTableData = () => {
    return userList.map((value, index) => (
      <tr key={index}>
        <td>{value.name}</td>
        <td>{value.email}</td>
        <td>{value.plBalance}</td>
        <td>{value.chBalance}</td>
        <td>
          <i className="fa fa-edit" onClick={() => handleModal(value)}></i>
          {value.ifActive ? (
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() =>
                updateUserDetails({ ifActive: 0, userId: value.id })
              }
            ></i>
          ) : (
            <i
              className="fa fa-refresh"
              aria-hidden="true"
              onClick={() =>
                updateUserDetails({ ifActive: 1, userId: value.id })
              }
            ></i>
          )}
        </td>
      </tr>
    ));
  };

  const updateUserDetails = (data) => {
    const api = data.userId
      ? "/user/updateUserDetails"
      : "/user/insertUserDetails";

    Axios.post(config.API_BASE + api, data)
      .then((response) => {
        if (response.data.status) {
          getUserList();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row userListMain">
          <div className="col-xs-12">
            <div className="card">
              <div className="title-div">
                <div className="titleText">User Lists</div>
                <Button variant="primary" onClick={() => handleModal()}>
                  Create New User
                </Button>
                <Button
                  variant={ifActive ? "success" : "danger"}
                  onClick={handleToggle}
                >
                  {ifActive ? "Show Inactive Users" : "Show Active Users"}
                </Button>
              </div>
              {userList.length === 0 ? (
                <h3>No Record Found</h3>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>PL Balance</th>
                        <th>CH Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{prepareTableData()}</tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AddUser
        show={showModal}
        handleClose={handleCloseModal}
        user={selectedUser}
        refreshUserList={getUserList}
      />
    </>
  );
};

export default UserList;
