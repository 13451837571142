import React from "react";
import Header from "../header/Header";
import Axios from "axios";
import moment from "moment";
import "../../components/attendanceReport/Report.css";
import $ from "jquery";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import "../../components/attendanceReport/Report.css";
import * as XLSX from "xlsx";
const config = require("../../config/config");
const queryString = require("query-string");

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportList: [],
      startDate: moment().startOf("month").format("ll"),
      endDate: moment().endOf("month").format("ll"),
    };

    this.getReport = this.getReport.bind(this);
    this.prepareTableRows = this.prepareTableRows.bind(this);
    this.handleStartDayChange = this.handleStartDayChange.bind(this);
    this.handleEndDayChange = this.handleEndDayChange.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
  }

  componentWillMount() {
    this.getReport();
  }

  handleStartDayChange(selectedDay, modifiers, dayPickerInput) {
    this.setState({
      startDate: selectedDay,
    });
  }

  handleEndDayChange(selectedDay, modifiers, dayPickerInput) {
    this.setState({
      endDate: selectedDay,
    });
  }

  getReport() {
    let that = this;
    const startOfMonth = moment(this.state.startDate).format(
      config.API_DATE_FORMAT
    );
    const endOfMonth = moment(this.state.endDate).format(
      config.API_DATE_FORMAT
    );
    let axiosData = {
      dateBetween: startOfMonth + "|" + endOfMonth,
    };
    Axios.get(
      config.API_BASE +
        "/user/getUserAttendanceReport?" +
        queryString.stringify(axiosData)
    )
      .then(function (response) {
        let res = response.data;
        if (res.status) {
          let reportList = typeof res.data !== "undefined" ? res.data : [];
          that.setState({ reportList: reportList });
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  prepareTableRows() {
    let rows = [];
    let emailFormat = "";

    this.state.reportList.map(function (value, index) {
      try {
        emailFormat = value.email.substring(0, value.email.lastIndexOf("@"));
        emailFormat = emailFormat.split(".").join(" ");
      } catch (err) {
        emailFormat = value.email;
      }
      rows.push(
        <tr key={index}>
          <td className="name">
            {value.name != null ? value.name : emailFormat}
          </td>
          <td>{value.totalDays}</td>
          <td>{value.presentMark}</td>
          <td>{value.workFromHome}</td>
          <td>{value.outOfOffice}</td>
          <td>{value.sickLeave}</td>
          <td>{value.earnedLeave}</td>
          <td>{value.optionalLeave}</td>
          <td>{value.sandwichedLeave}</td>
          <td>{value.compulsaryLeaveTotals}</td>
          <td>{value.weekEndsTotals}</td>
          <td>{value.optionalLeaveTotals}</td>
          <td>{value.missedMark}</td>
        </tr>
      );
    });

    return rows;
  }

  exportToExcel() {
    const { reportList } = this.state;
    const ws = XLSX.utils.json_to_sheet(reportList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "Report.xlsx");
  }

  render() {
    let tableRows = this.prepareTableRows();
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="reportSectionMain card">
            <div className="row filterSection">
              <div className="col-xs-12 displayFlex">
                <div className="form-group">
                  <label className="startDateInput" htmlFor="startDateInput">
                    Start Date
                  </label>
                  <div id="startDateInput">
                    <DayPickerInput
                      value={this.state.startDate}
                      onDayChange={this.handleStartDayChange}
                      placeholder="Start Date"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="LL"
                      dayPickerProps={{
                        format: "YYYY M D",
                        selectedDays: this.state.startDate,
                        disabledDays: [
                          {
                            after: new Date(this.state.endDate),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
                <div className="form-group endDateFormGroup">
                  <label className="endDateInput" htmlFor="endDateInput">
                    End Date
                  </label>
                  <div id="endDateInput">
                    <DayPickerInput
                      value={this.state.endDate}
                      placeholder="End Date"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="LL"
                      onDayChange={this.handleEndDayChange}
                      dayPickerProps={{
                        format: "YYYY-M-D",
                        selectedDays: this.state.endDate,
                        disabledDays: [
                          {
                            before: new Date(this.state.startDate),
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
                <button className="filterBtn btn" onClick={this.getReport}>
                  Filter
                </button>
                <button className="filterBtn btn" onClick={this.exportToExcel}>
                  Export to Excel
                </button>
              </div>
            </div>
            <div className="row tableSection">
              <div className="col-xs-12">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered table-hover table-condensed">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Total Days</th>
                        <th>Present</th>
                        <th>Work From Home</th>
                        <th>Out Of Office</th>
                        <th>Sick Leave</th>
                        <th>Earned Leave</th>
                        <th>Optional Leave</th>
                        <th>Sandwiched Leave</th>
                        <th>Compulsary Leave</th>
                        <th>Weekend Leave</th>
                        <th>Optional Leave Totals</th>
                        <th>Missed Mark</th>
                      </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Report;
